<template lang="pug">
ViewportBackgroundContainer
  .py-3.py-md-5
    h3.text-center.pb-3.serif-font(data-i18n="blogHomeListTitle") {{ $t('blogHomeListTitle') }}
    ScreenWidthProvider(v-slot="{ isNarrower, isWider }")
      .d-none.d-md-block(v-if="isWider('md', 1) || !hydrated")
        Swiper(v-bind="sliderOptions")
          HomeBlogCard.my-4(
            v-for="post in list"
            :key="`t-${post.id}`"
            :post="post"
          )

      .d-block.d-md-none(v-if="isNarrower('md', 1) || !hydrated")
        Swiper(v-bind="sliderOptions")
          HomeBlogCard(
            v-for="post in list"
            :key="`t-${post.id}`"
            :post="post"
          )
      .d-flex.justify-content-center.mt-5.w-100
        button.btn.btn-blue.rounded-5.px-5.py-2(
          data-i18n="seeAll"
          @click="navigateTo(blogUrl)"
        ) {{ $t('seeAll') }}
</template>

<script>
import { mapState } from 'pinia'
import { SCREEN_WIDTH_SIZES } from '@packages/screen/constants/sizes.js'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()
    const blogUrl = localeURLs.blog

    return {
      blogUrl,
    }
  },

  data () {
    return {
      sliderOptions: {
        slidesPerView: 1,
        pagination: true,
        dotsAlign: 'outside',
        dotsType: 'dark',
        spaceBetween: 30,
        constantDisplayNavigation: true,
        navigation: true,
        arrowsType: 'circular',
        loadingSkeletonAnimation: 'skeleton-slides',
        loadingSkeletonAspect: false,
        loadingSkeletonHeight: '400px',
        loop: true,
        onlyRenderVisibleSlides: true,
        preventSwiperUpdate: true,
        autoplay: {
          delay: 5000,
        },

        breakpoints: {
          [SCREEN_WIDTH_SIZES.md]: {
            slidesPerView: 3,
          },
        },
      },
    }
  },

  computed: {
    ...mapState(useBlogStore, {
      rawList: 'list',
    }),

    list () {
      let showOnHomeItems = this.rawList
        .filter(item => item.featured_on_startpage === 1)
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

      if (showOnHomeItems.length < 9) {
        const additionalItems = this.rawList
          .filter(item => !showOnHomeItems.includes(item))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

        showOnHomeItems = showOnHomeItems.concat(additionalItems).slice(0, 9)
      }

      return showOnHomeItems
    },
  },

  created () {
    this.hydrated = false
  },

  mounted () {
    this.hydrated = !!process.browser
  },
})
</script>

<style lang="scss" scoped>
.btn {
  text-transform: none;
  font-size: 1.5rem;
}
</style>
